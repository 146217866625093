import { ResizableBox } from 'react-resizable'

const ResizableHeaderCell = ({ width, onResizeProp,colName, children }) => {

  const minWidth=colName ? colName.length*14:0

  return(
    <th>
      <ResizableBox
        className='position-relative'
        width={width ?? 150}
        axis='x'
        handle={<div className="custom-handle" />}
        minConstraints={[(minWidth > 150? minWidth :150), 10]}
        maxConstraints={[Infinity, Infinity]}
        onResizeStop={(e, { size }) => {
          onResizeProp(size.width) }}
      >
        {children}
      </ResizableBox>
    </th>
  )}

export default ResizableHeaderCell