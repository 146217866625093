import { ROUTES } from './Routes'
import axios from '../utils/axios/auth'
import qs from 'qs'

const getColumnSize = async(table) => {
  try{
    const response=await axios.get(`${ROUTES.GET.TABLE_MANAGEMENT}?table_name=${table}`)
    return response
  }catch(error){
    return Promise.reject(error)
  }
}

const updateColumnSize = async(payload) => {
  try{
    const response=await axios.post(ROUTES.POST.TABLE_MANAGEMENT,payload)
    return response
  }catch(error){
    return Promise.reject(error)
  }
}

const activityApi = async(pageNo,id) => {
  try{
    const params = {
      ...pageNo && { page: pageNo }
    }
    const response = await axios.get(`${ROUTES.GET.ACTIVITY}${id}`, {params, paramsSerializer: params => {
      return qs.stringify(params, {indices: false})
    }})
    return response
  }catch(error){
    console.log(error)
    return Promise.reject(error)
  }
}

export {
  updateColumnSize,
  getColumnSize,
  activityApi
}