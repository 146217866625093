import { ROUTES } from '../../../api/Routes'
import axios from '../../../utils/axios/auth'

const forgetPasswordEmailCheck = async({ email }) => {
  try {
    const response = await axios.post(ROUTES.POST.FORGET_PASSWORD_EMAIL, {email})
    return response.data
  } catch (error) {
    return Promise.reject(error)
  }
}

const forgetPasswordConfirmation = async(payload) => {
  try {
    const response = await axios.post(ROUTES.POST.FORGET_PASSWORD_CONFIRM, payload)
    return response.data
  } catch (error) {
    return Promise.reject(error)
  }
}
export {
  forgetPasswordEmailCheck , 
  forgetPasswordConfirmation
}