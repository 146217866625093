import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  login: {
    user: null,
  },
  resetPassword : {
    success: false,
    error: null,
    data: null,
  }
}

const slice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    storeUserDetails: (state, action) => {
      state.login.user = action.payload.userDetails
    },
    removeUserDetails: (state) => {
      state.login.user = null
    }
  },
})

export const { storeUserDetails, removeUserDetails } = slice.actions
export default slice.reducer
