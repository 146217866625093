import React from 'react'
import { Routes, Route } from "react-router-dom"

//Layouts
// import NonAuthLayout from "../Layouts/NonAuthLayout"
import VerticalLayout from "../Layouts/index"

//routes
import { authProtectedRoutes, publicRoutes } from "./allRoutes"
import { AuthProtected } from './AuthProtected'
import Public from './Public'
// import Public from './Public'

const Index = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route>
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <Public>
                  {/* <NonAuthLayout> */}
                  {route.component}
                  {/* </NonAuthLayout> */}
                </Public>

              }
              key={idx}
              exact={true}
            />
          ))}
        </Route>

        <Route>
          {authProtectedRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <AuthProtected>
                  {route.path !== '/reset-password' ? <VerticalLayout>{route.component}</VerticalLayout> : <>{route.component}</>}
                </AuthProtected>}
              key={idx}
              exact={true}
            />
          ))}
        </Route>
      </Routes>
    </React.Fragment>
  )
}

export default Index