import axios from 'axios'
import Cookies from 'js-cookie'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = Cookies.get("token")
    if (accessToken) {
      if (config.headers) config.headers.Authorization = accessToken
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401 && error.response.data.message === 'Unauthorized') {
      window.location.href = '/login'
    }
    return Promise.reject(error)
  }
)
export default axiosInstance