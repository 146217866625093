import { createSlice , createAsyncThunk } from '@reduxjs/toolkit'
import authAxios from '../utils/axios/auth'
import { ROUTES } from '../api/Routes'

const initialState = {
  addUser: {
    success: false,
    user: null,
    error: null,
  },
}

const slice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addUser.fulfilled, (state, action) => {
        state.addUser.success = true
        state.addUser.user = action.payload
      })
      .addCase(addUser.rejected, (state, action) => {
        state.addUser.error = action.payload
      })
  },
})
export default slice.reducer

export const addUser = createAsyncThunk(
  'user/addUser',
  async (body, { rejectWithValue }) => {
    try {
      const response = await authAxios.post(ROUTES.POST.CREATE_USER, body)
      return response
    } catch (error) {
      if (error.response?.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error)
      }
    }
  })