import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

const Navdata = () => {
  const history = useNavigate()
  //state data
  const [isDashboard, setIsDashboard] = useState(false)
  const [isSubscription, setIsSubscription] = useState(false)
  const [isNotifications, setIsNotifications] = useState(false)

  // eslint-disable-next-line no-unused-vars
  const [isLanding, setIsLanding] = useState(false)

  const [iscurrentState, setIscurrentState] = useState('Dashboard')

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu")
      const iconItems = ul.querySelectorAll(".nav-icon.active")
      let activeIconItems = [...iconItems]
      activeIconItems.forEach((item) => {
        item.classList.remove("active")
        var id = item.getAttribute("subitems")
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show")
      })
    }
  }

  useEffect(() => {
    document.body.classList.remove('twocolumn-panel')
    if (iscurrentState !== 'Dashboard') {
      setIsDashboard(false)
    }
    if (iscurrentState !== 'Subscription') {
      setIsSubscription(false)
    }
    if (iscurrentState !== 'Notifications') {
      setIsNotifications(false)
    }
    if (iscurrentState !== 'Landing') {
      setIsLanding(false)
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isSubscription,
    isNotifications
  ])

  const menuItems = [
    {
      id: "dashboard",
      label: "Dashboard",
      icon: "ri-terminal-window-line",
      link: "/dashboard",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault()
        setIsDashboard(!isDashboard)
        setIscurrentState('Dashboard')
        updateIconSidebar(e)

      },
    },
    {
      id: "subscriptions",
      label: "Subscription Management",
      icon: "ri-survey-line",
      link: "/subscriptions",
      stateVariables: isSubscription,
      click: function (e) {
        e.preventDefault()
        setIsSubscription(!isSubscription)
        setIscurrentState('Digital Forms')
        updateIconSidebar(e)
      },
    },
    // {
    //   id: "notifications",
    //   label: "Notifications",
    //   icon: "ri-notification-line",
    //   link: "/notifications",
    //   stateVariables: isNotifications,
    //   click: function (e) {
    //     e.preventDefault()
    //     setIsNotifications(!isNotifications)
    //     setIscurrentState('notifications')
    //     updateIconSidebar(e)
    //   }
    // },
  ]
  let modifiedMenuItems = menuItems
  return <React.Fragment>{modifiedMenuItems}</React.Fragment>
}
export default Navdata