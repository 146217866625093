import PropTypes from "prop-types"
import React, { useState } from "react"
import { Row, Col, Alert, Card, CardBody, Container, Input, Label, Form } from "reactstrap"

import { Link, useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { useFormik } from "formik"

import ParticlesAuth from "../../AuthenticationInner/ParticlesAuth"
import logoLight from "../../../assets/images/vardian-logo.png"
import withRouter from "../../../Components/Common/withRouter"
import { forgetPasswordConfirmation, forgetPasswordEmailCheck } from "./api"

const ForgetPasswordPage = props => {

  const [showResetFields, setShowResetFields] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: '',
      otp: '',
      new_password: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: async (values) => {
      try {
        if (showResetFields) {
          const resetPayload = {
            otp: values.otp,
            email: values.email,
            new_password: values.password,
          }
          await forgetPasswordConfirmation(resetPayload)
          navigate("/login")
        } else {
          const emailPayload = {
            email: values.email,
          }
          const response = await forgetPasswordEmailCheck(emailPayload)
          if (response.message) {
            setShowResetFields(true)
            setErrorMessage(null)
          }
        }
      } catch (error) {
        console.error("Error during form submission:", error)
        setErrorMessage(error.response.data.message)
      }
    }
  })

  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        <Container>
          <Row className="justify-content-center">
            <Col md={10} lg={16} xl={10}>
              <Card>
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <div className="text-center margin-b-20 text-white-50">
                      <div className='mb-3'>
                        <Link to="#" className="d-inline-block auth-logo">
                          <img style={{ maxWidth: '210px' }} src={logoLight} alt="" />
                        </Link>
                      </div>
                      <h3 className='text-primary'>Forget Password</h3>
                    </div>
                    <lord-icon
                      src="https://cdn.lordicon.com/rhvddzym.json"
                      trigger="loop"
                      colors="primary:#0ab39c"
                      className="avatar-xl"
                      style={{ width: "120px", height: "120px" }}
                    >
                    </lord-icon>
                  </div>

                  <Alert className="alert-borderless alert-warning text-center mb-2 mx-2" role="alert">
                    Enter your email and instructions will be sent to you!
                  </Alert>
                  {errorMessage? (<Alert color="danger"> {errorMessage} </Alert>) : null}
                  <div className="p-2">
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <div className="mb-4">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          autoComplete="off"
                          helperText={validation.touched.email && validation.errors.email? validation.errors.email : null}
                          FormHelperTextProps={{
                            sx: {
                              color: 'red',
                            },
                          }}
                        />
                      </div>

                      {showResetFields && (
                        <>
                          <div className="mb-4">
                            <Input
                              name="otp"
                              className="form-control"
                              placeholder="Enter otp"
                              type="number"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.otp || ""}
                              autoComplete="off"
                            />
                          </div>
                          <div className="mb-4">
                            <Input
                              name="password"
                              className="form-control"
                              placeholder="Enter password"
                              type="password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ""}
                              autoComplete="off"
                            />
                          </div>
                        </>
                      )}
                      <div className="text-center mt-4">
                        <button className="btn btn-success w-100" type="submit">{showResetFields ? "Submit" : "Send OTP"}</button>
                      </div>
                    </Form>
                  </div>
                  <div className="mt-4 text-center">
                    <p className="text-muted">Wait, I remember my password... <Link to="/login" className="fw-semibold text-primary text-decoration-underline"> Click here </Link> </p>
                  </div>
                </CardBody>
              </Card>       
            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
  )
}

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ForgetPasswordPage)
