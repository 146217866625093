import React from "react"
import { Row, Col, Button, FormGroup, Input } from "reactstrap"
import { TextField } from '@mui/material'


const Feedback = () => {
  document.title ="Feedback | Vardian"
  
  return (
    <React.Fragment>
      <div className='modal_body'>
        <div className='heading_holder'>
          <p style={{ fontWeight: 'bold', color: '#000' }} className='mb-0'>Submit Your Feedback!</p>
        </div>
        <div className='content_holder'>Your comments are important for us and crucial in helping us provide the best service.</div>
        <form>
          <Row>
            <Col xs={12}>
              <FormGroup>
                <Input
                  placeholder='Your Comment'
                  id="exampleText"
                  name="text"
                  type="textarea"
                />
              </FormGroup>
            </Col>
            <Col xs={12} className='mb-3'>
              <TextField 
                fullWidth 
                label="Your Mail Address" 
                variant="outlined" 
              />
            </Col>
            <Col xs={12}>
              <Button color="primary" className='d-inline-flex gradient_blue_bg'> 
                <span>Submit</span>
              </Button>
            </Col>
          </Row>
        </form>
      </div>
    </React.Fragment>
  )
}

export default Feedback