const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')

dayjs.extend(utc)

export const convertDateTimeString = (utcDateParam, format='MMM DD, YYYY HH:mm', convertTo = 'LOCAL') => {
  // Assuming 'yourUtcDate' is your UTC timestamp
  const yourUtcDate = utcDateParam

  // Parse UTC date
  const utcDate = dayjs.utc(yourUtcDate)

  // Display in local timezone
  const localDate = utcDate.local()

  if (convertTo === 'LOCAL') {
    return localDate.format(format)
  } else {
    return utcDate.format(format)
  }
}