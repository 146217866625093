import React, { useState } from "react"
import { CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback } from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { Link, useNavigate } from "react-router-dom"

//import images 
import logoLight from "../../../assets/images/vardian-logo.png"
import ParticlesAuth from "../../AuthenticationInner/ParticlesAuth"
import { resetPasswordApi } from "./api"
import { useDispatch } from "react-redux"
import { removeUserDetails } from "../../../store/auth"
import Cookies from "js-cookie"

const ResetPassword = () => {
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      old_password : '',
      new_password: '',
    },
    validationSchema: Yup.object({
      old_password: Yup.string().required("Please Enter Your Old Password"),
      new_password: Yup.string().required("Please Enter Your New Password"),
    }),
    onSubmit: async(values) => {
      try {
        await resetPasswordApi(values)
        dispatch(removeUserDetails())
        Cookies.remove("token")
        navigate("/login")
      } catch (error) {
        setErrorMessage(error.response.data.message)
      }
    }
  })

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Card>
              <CardBody className="p-4">
                <div className="text-center margin-b-20 text-white-50">
                  <div className='mb-3'>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img style={{ maxWidth: '210px' }} src={logoLight} alt="" />
                    </Link>
                  </div>
                </div>
                <div className="p-2 mt-4">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                    className="needs-validation" action="#">
                    {errorMessage? (<Alert color="danger"> {errorMessage} </Alert>) : null}
                    <div className="mb-3">
                      <Label htmlFor="userpassword" className="form-label">Old Password <span className="text-danger">*</span></Label>
                      <Input
                        name="old_password"
                        type="password"
                        placeholder="Enter Old Password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.old_password || ""}
                        invalid={
                          validation.touched.old_password && validation.errors.old_password ? true : false
                        }
                      />
                      {validation.touched.old_password && validation.errors.old_password ? (
                        <FormFeedback type="invalid"><div>{validation.errors.old_password}</div></FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label htmlFor="userpassword" className="form-label">New Password <span className="text-danger">*</span></Label>
                      <Input
                        name="new_password"
                        type="password"
                        placeholder="Enter New Password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.new_password || ""}
                        invalid={
                          validation.touched.new_password && validation.errors.new_password ? true : false
                        }
                      />
                      {validation.touched.new_password && validation.errors.new_password ? (
                        <FormFeedback type="invalid"><div>{validation.errors.new_password}</div></FormFeedback>
                      ) : null}
                    </div>
                    <div className="mt-4">
                      <button className="btn btn-success w-100" type="submit">Reset</button>
                    </div>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  )
}

export default ResetPassword
