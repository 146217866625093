import Cookies from 'js-cookie'
import { ROUTES } from '../../../api/Routes'
import axios from '../../../utils/axios/auth'

const loginApi = async({ email, password }) => {
  try {
    const response = await axios.post(ROUTES.POST.LOGIN, {email, password})
    return response.data
  } catch (error) {
    return Promise.reject(error)
  }
}

const userDetailsApi = async() => {
  try {
    const response = await axios.get(ROUTES.GET.USER_DETAILS)
    return response?.data
  } catch (error) {
    if(error.code === "ERR_NETWORK" ){
      Cookies.remove("token")
    }
    return Promise.reject(error)
  }
}

export {
  loginApi,
  userDetailsApi
}