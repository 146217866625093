import { CircularProgress } from "@mui/material"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { generateLicenseKeyApi } from "../../pages/Subscriptions/api"

const GenerateLicenseKey = ({ id, show, onCloseClick }) => {
  const [isLoading, setIsLoading] = useState(false)
  const onYesClick = async () => {
    try {
      setIsLoading(true)
      const response = await generateLicenseKeyApi(id)
      const url = response.data.data.location
      downloadFile(url)
      setIsLoading(false)
      onCloseClick()
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  const downloadFile = (url) => {
    const link = document.createElement('a')
    link.href = url
    // link.download = 'export.txt' // Change the filename as needed
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalHeader toggle={onCloseClick}></ModalHeader>
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          <lord-icon
            src="https://cdn.lordicon.com/nocovwne.json"
            trigger="loop"
            colors="primary:#0ab39c,secondary:#f06548"
            style={{ width: "100px", height: "100px" }}
          >
          </lord-icon>
          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
            <h4>Are you sure ?</h4>
            <p className="text-muted mx-4 mb-0">Are you sure you want to generate license key ?</p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <button
            type="button"
            className="btn w-sm btn-light"
            data-bs-dismiss="modal"
            onClick={onCloseClick}
            disabled={isLoading}
          >
          Close
          </button>
          <button
            type="button"
            className="btn w-sm btn-danger "
            id="delete-record"
            onClick={() => {onYesClick()}}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress color="inherit" size={12} /> : 'Generate'}
          </button>
        </div>
      </ModalBody>
    </Modal>
  )
}

GenerateLicenseKey.propTypes = {
  onCloseClick: PropTypes.func,
  data: PropTypes.any,
  show: PropTypes.any,
}

export default GenerateLicenseKey