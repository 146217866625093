import React, { Suspense, lazy } from "react"
// import { useSelector } from "react-redux"
import { CircularProgress } from "@mui/material"
const Subscriptions = lazy(() => import('./Tabs/Subscriptions/index'))

const DashboardEcommerce = () => {
  // const user = useSelector(state => ({
  //   user : state.auth.login.user.user.name
  // }))
  document.title ="Dashboard | Vardian"

  return (
    <React.Fragment>
      <div className="page-content">
        {/* <h3 className='mb-3 p-0'> Welcome, {user.user}!</h3> */}
        <h3 className='mb-3 px-2' style={{ fontSize: '18px', color: '#041B29' }}> Welcome! to <span style={{fontSize: '24px', color: '#1C40CA'}}>Vardian Client Management Portal</span></h3>
        <Suspense fallback={
          <div className="loader-alignment w-full">
            <div style={{ textAlign: 'center' }}>
              <CircularProgress color="info" size={100} />
              <div className="mt-4" style={{fontSize: '20px', fontWeight: 'bold', color: 'inherit'}}>Loading your dashboard...</div>
            </div>
          </div>
        }>
          <Subscriptions/>
        </Suspense>
      </div>
    </React.Fragment>
  )
}

export default DashboardEcommerce
