import { ROUTES } from "../../api/Routes"
import axios from "../../utils/axios/auth"
import qs from 'qs'

const getNotificationData = async(param) => {
  try {
    // const params = {
    //   order: param.column,
    //   direction: param.order,
    //   page: param.page ?? 1,
    //   filterFields: param.filterFields,
    //   filterInputs: param.filterInputs,
    //   search: param.search,
    //   limit: param.limit
    // }
    // const response = await axios.get(ROUTES.GET.NOTIFICATION_LIST, {params, paramsSerializer: params => {
    //   return qs.stringify(params, {indices: false})
    // }})
    const response = {data:
    {
      data:
    {
      data:[
        {"id": 3617,
          "users": 12,
          "company_name": "ABC Technologies",
          'next_renewal_date': '12-12-2024',
          'time_remaining': '84 days',
          'plan': 'Premium'
        },
        {"id": 3619,
          "users": 42,
          "company_name": "Neo Matrix",
          'next_renewal_date': '06-06-2024',
          'time_remaining': '14 days',
          'plan': 'Free'
        },
        {"id": 3617,
          "users": 21,
          "company_name": "Dexian",
          'next_renewal_date': '11-10-2024',
          'time_remaining': '68 days',
          'plan': 'Premium'
        }
      ]
    }
    }
    }
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}

const getAllCompaniesApi = async(param) => {
  try{
    const params = {
      sort: param?.column,
      order: param?.order,
      page: param?.page ?? 1,
      filterFields: param?.filterFields,
      filterInputs: param?.filterInputs,
      search: param?.search,
      limit: param?.limit
    }

    const response = await axios.get(ROUTES.GET.COMPANIES, {params, paramsSerializer: params => {
      return qs.stringify(params, {indices: false})
    }})
    return response
  }catch(error){
    return Promise.reject(error)
  }
}

const getAllSubscriptionPlansApi = async(param) => {
  try{
    const params = {
      order: param?.column,
      direction: param?.order,
      page: param?.page ?? 1,
      filterFields: param?.filterFields,
      filterInputs: param?.filterInputs,
      search: param?.search,
      limit: param?.limit
    }
    const response = await axios.get(ROUTES.GET.SUBSCRIPTION_PLANS, {params, paramsSerializer: params => {
      return qs.stringify(params, {indices: false})
    }})
    return response
  }catch(error){
    return Promise.reject(error)
  }
}

const addCompanyApi = async(payload) => {
  try{
    const response = await axios.post(ROUTES.POST.COMPANIES,payload)
    return response
  }catch(error){
    return Promise.reject(error)
  }
}

const companyViewApi = async(id) => {
  try{
    const response = await axios.get(`${ROUTES.POST.COMPANIES}/${id}`)
    return response
  }catch(error){
    return Promise.reject(error)
  }
}

const stripeRefund = async(id) => {
  try{
    const response = await axios.post(ROUTES.POST.STRIPE_REFUND , {id})
    return response
  }
  catch(error) {
    console.log(error)
    return Promise.reject(error)
  }
}

const companyEditApi = async(id,payload) => {
  try{
    const response = await axios.put(`${ROUTES.PUT.COMPANIES}/${id}`,payload)
    return response
  }catch(error){
    return Promise.reject(error)
  }
}

const companyDeleteApi = async (id) => {
  try {
    const response = await axios.delete(`${ROUTES.DELETE.COMPANIES}/${id}`)
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}

const generateLicenseKeyApi = async(id) => {
  try{
    const response = await axios.get(`${ROUTES.GET.LICENSE_KEY}/${id}`)
    return response
  }catch(error){
    return Promise.reject(error)
  }
}

export {
  getNotificationData,
  getAllCompaniesApi,
  getAllSubscriptionPlansApi,
  addCompanyApi,
  companyViewApi,
  companyEditApi,
  stripeRefund,
  companyDeleteApi,
  generateLicenseKeyApi
}