import React, {} from 'react'
import { useSelector } from 'react-redux'
import { Button, Offcanvas, OffcanvasBody } from 'reactstrap'
import SimpleBar from "simplebar-react"

const ProfileSidebar=({profileSidebar,toggleProfileSidebar})=>{

  const { user } = useSelector(state => {
    return{
      user: state.auth.login.user
    }}
  )

  return (
    <React.Fragment>
      {/* Filter sidebar */}
      <Offcanvas isOpen={profileSidebar} toggle={toggleProfileSidebar} direction="end" className="offcanvas-end border-0 bottom_save_offcanvas">
        <div className="d-flex justify-content-between align-items-center p-4">
          <h4 className="bold_heading mb-0">Profile Details</h4>
          <Button className='d-inline-flex transparent_btn only_icon' onClick={toggleProfileSidebar}>
            <i className="ri-close-circle-line"></i>
          </Button>
        </div>
        <OffcanvasBody className="p-0">
          <SimpleBar className="simplebar_holder">
            <div className='details_user_sidebar'>
              <div style={{ justifyContent: "flex-start" }} className="dark_bg_holder details_user_sidebar_info p-4">
                <div className='name_initials_holder me-3'>{user?.user.name.split(" ").map(word=>word[0]).join("")}</div>
                <div>
                  <label className="label_holder">Name</label>
                  <p style={{ fontSize:"25px" }} className="info_title">{user?.user.name}</p>
                </div>

              </div>
              {/* <div className="details_user_sidebar_info">
                <label className="label_holder d-flex">
                  <i style={{ fontSize:"18px" }} className='ri-user-star-line'></i>
                  <span className='ps-2'>Role</span>
                </label>
                <p className="info_title">{user?.role?.display_name}</p>
              </div> */}
              <div className="details_user_sidebar_info">
                <label className="label_holder d-flex">
                  <i style={{ fontSize:"18px" }} className='ri-mail-line'></i>
                  <span className='ps-2'>Email</span>
                </label>
                <p className="info_title">{user?.user.email ?? 'N/A'}</p>
              </div>
              <div className="details_user_sidebar_info">
                <label className="label_holder d-flex">
                  <i style={{ fontSize:"18px" }} className='ri-folder-user-line'></i>
                  <span className='ps-2'>Username</span>
                </label>
                <p className="info_title">{user?.user.username ?? 'N/A'}</p>
              </div>
            </div>
          </SimpleBar>
          <div className='save_btm_holder text-center'>
            <Button color="primary" onClick={toggleProfileSidebar} className='d-inline-flex gradient_blue_bg'>
              <span>Close</span>
            </Button>
          </div>
        </OffcanvasBody>
      </Offcanvas>
    </React.Fragment>
  )
}

export default ProfileSidebar