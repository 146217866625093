import Cookies from 'js-cookie'
import Swal from 'sweetalert2'
import ProfileSidebar from './profileSidebar'
import { useNavigate } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import { removeUserDetails } from "../../store/auth"
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

const ProfileDropdown = () => {
  const dispatch = useDispatch()
  const [userName, setUserName] = useState("Admin")
  const [profileSidebar,setProfileSidebar]=useState(false)

  const toggleProfileSidebar=()=>{
    setProfileSidebar(!profileSidebar)
  }

  const { user } = useSelector(state => ({
    user: state.auth.login.user
  }))

  useEffect(() => {
    setUserName(user.user.name)
  }, [userName, user])

  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false)
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown)
  }

  const history = useNavigate()
  const handleLogoutClick = () => {
    // Remove the item from local storage
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure you want to logout?',
      showConfirmButton: true,
      reverseButtons: true,
      confirmButtonColor: '#3085d6',
      // timer: 1500,
      showCancelButton: true
    }).then((isConfirm)=> {
      if(isConfirm.isConfirmed){
        Cookies.remove("token")
        dispatch(removeUserDetails())
        history("/login")
      }
    })
  }

  return (
    <React.Fragment>
      <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
        <DropdownToggle tag="button" type="button" className="btn">
          <span className="d-flex align-items-center">
            {/* <img className="rounded-circle header-profile-user" src={avatar1}
              alt="Header Avatar" /> */}
            <div className="user_name_holder">
              {userName.split(' ').map(word => word.charAt(0))}
            </div>
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{userName}</span>
              <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">Super Admin</span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">

          {/* <h6 className="dropdown-header">Welcome, {userName}!</h6> */}
          <DropdownItem onClick={toggleProfileSidebar}><i className="mdi mdi-account-circle text-muted fs-16 align-middle"></i>
            <span className="align-middle ps-1">Profile</span></DropdownItem>
          {/* <DropdownItem href="/apps-chat"><i
            className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i> <span
            className="align-middle">Messages</span></DropdownItem>
          <DropdownItem href="#"><i
            className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i> <span
            className="align-middle">Taskboard</span></DropdownItem>
          <DropdownItem href="/pages-faqs"><i
            className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i> <span
            className="align-middle">Help</span></DropdownItem>
          <div className="dropdown-divider"></div>
          <DropdownItem href="/pages-profile"><i
            className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i> <span
            className="align-middle">Balance : <b>$5971.67</b></span></DropdownItem>
          <DropdownItem href="/pages-profile-settings"><span
            className="badge bg-soft-success text-success mt-1 float-end">New</span><i
            className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i> <span
            className="align-middle">Settings</span></DropdownItem>
          <DropdownItem href="/auth-lockscreen-basic"><i
            className="mdi mdi-lock text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Lock screen</span></DropdownItem> */}
          <DropdownItem onClick={handleLogoutClick}>
            <i className="mdi mdi-logout text-muted fs-16 align-middle"></i>{" "}
            <span className="align-middle" data-key="t-logout ps-1">
              Logout
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <ProfileSidebar
        profileSidebar={profileSidebar}
        setProfileSidebar={setProfileSidebar}
        toggleProfileSidebar={toggleProfileSidebar}
      />
    </React.Fragment>
  )
}

export default ProfileDropdown