import React, { useState } from 'react'
import { Card, CardBody, Container, Alert, Button, Spinner } from 'reactstrap'
import { TextField, Box } from '@mui/material'
import ParticlesAuth from "../../AuthenticationInner/ParticlesAuth"
import { storeUserDetails } from '../../../store/auth'
import Cookies from 'js-cookie'
//redux
import { useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import logoLight from "../../../assets/images/vardian-logo.png"
import withRouter from '../../../Components/Common/withRouter'
import { loginApi, userDetailsApi } from './api'

const Login = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  // const [isLoading]

  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().trim('The email cannot include leading and trailing spaces').strict(true).required("Please Enter Your or Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: async(values) => {
      try {
        const response = await loginApi(values)
        console.log('response login ', response)
        let userDetails
        if(response){
          Cookies.set('token', response.token, { expires: 7 })
          console.log('Cookies ', Cookies)
          userDetails = await userDetailsApi()
          console.log('userDetails  ', userDetails)
          dispatch(storeUserDetails({ userDetails }))
          navigate("/dashboard")
          // if(userDetails?.user){
          //   navigate("/dashboard")
          // }
          // if(userDetails?.user?.is_password_reset){
          //   navigate("/dashboard")
          // }else{
          //   navigate("/reset-password")
          // }
        }
      } catch (error) {
        console.log('error', error)
        setErrorMessage(error.response.data.message)
      }
    }
  })

  document.title = "Login | Vardian"
  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Card>
              <CardBody className="p-4">
                <div className="text-center margin-b-20 text-white-50">
                  <div className='mb-3'>
                    <Link to="#" className="d-inline-block auth-logo">
                      <img style={{ maxWidth: '210px' }} src={logoLight} alt="" />
                    </Link>
                  </div>
                  <h2 className='heading mb-0'>Login</h2>
                </div>
                {errorMessage? (<Alert color="danger"> {errorMessage} </Alert>) : null}
                <div className="p-2 mt-4">
                  <Box component='form'
                    onSubmit={(e) => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                    action="#">
                    <div className='input_withicon_holder margin-b-20'>
                      <TextField
                        fullWidth
                        placeholder="Email"
                        variant="outlined"
                        autoComplete='off'
                        value={validation.values.email || ""}
                        onChange={(event) => validation.setFieldValue("email", event.target.value)}
                        onBlur={validation.handleBlur}
                        helperText={validation.touched.email && validation.errors.email ? validation.errors.email : null}
                        FormHelperTextProps={{
                          sx: {
                            color: 'red',
                          },
                        }}
                      />
                      <i className="ri-user-line"></i>
                    </div>
                    <div className='input_withicon_holder margin-b-20'>
                      <TextField
                        fullWidth
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        variant="outlined"
                        autoComplete='off'
                        value={validation.values.password}
                        onChange={(event) => validation.setFieldValue("password", event.target.value)}
                        helperText={
                          validation.touched.password && validation.errors.password ? validation.errors.password : null
                        }
                        FormHelperTextProps={{
                          sx: {
                            color: 'red',
                          },
                        }}
                      />
                      {showPassword ? <i style={{ cursor: 'pointer' }} class="ri-eye-line" onClick={handleShowPassword} /> : <i style={{ cursor: 'pointer' }} class="ri-lock-line" onClick={handleShowPassword} />}
                    </div>
                    {/* <div className="text-center my-4">
                      <Link to="/forgot-password">Forgot password?</Link>
                    </div> */}
                    <div className="text-center mt-4">
                      <Button color="primary" className="btn btn-primary gradient_blue_btn" type="submit"
                        disabled={validation.isSubmitting}
                      >
                        {validation.isSubmitting && <Spinner size="sm" />}
                        &nbsp; Login
                      </Button>
                    </div>
                  </Box>
                </div>
              </CardBody>
            </Card>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  )
}

export default withRouter(Login)
