import { IconButton, Tooltip } from "@mui/material"
import { Button, Offcanvas, OffcanvasBody } from "reactstrap"
import SimpleBar from "simplebar-react"
import React, { useEffect, useState } from 'react'
import { getNotificationSettings, getPlatformModuleActions, postPlatformModuleActions } from "./api"
import Swal from "sweetalert2"

const Settings = (props) => {

  const [module, setModule] = useState([])

  const fetchModules = async () => {
    try {
      const response = await getPlatformModuleActions()
      const settings = await getNotificationSettings()

      if (settings.data.data.length === 0) {
        setModule(response.data.data)
      } else {
        setModule(() => {
          const modules = response.data.data.map((module) => {
            return {
              id:module.id,
              display_name: module.display_name,
              note: module.note,
              actions: module.actions.map((action) => {
                return{
                  action_id:action.id,
                  name:action.name,
                  display_name: action.display_name,
                  ...(settings.data.data.filter((setting) => setting?.action?.id === action?.id)?.length > 0 ?
                    {
                      id:settings.data.data.filter((setting) => setting?.action?.id === action?.id)[0].id,
                      push_enabled: settings.data.data.filter((setting) => setting?.action?.id === action?.id)[0].push_enabled,
                      email_enabled: settings.data.data.filter((setting) => setting?.action?.id === action?.id)[0].email_enabled
                    }
                    :
                    {
                      id:null,
                      push_enabled: false,
                      email_enabled: false
                    })
                }
              })
            }
          })
          return modules
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleCheckboxChange = (action, type, checked) => {
    setModule((prevModules) => {
      return prevModules.map((mod) => {
        return {
          ...mod,
          actions: mod.actions.map((act)=> {
            if (mod.display_name === 'Digital Form') {
              if (action.name === 'all_updates') {
                return {
                  ...act,
                  [`${type}_enabled`]: checked
                }
              } else {
                return act.id === action.id ? {
                  ...act,
                  [`${type}_enabled`]: checked
                } : (
                  act.name === 'all_updates' ? {
                    ...act,
                    [`${type}_enabled`]: false
                  } : act
                )
              }
            } else if (mod.display_name === 'Document Management') {
              if (action.name === 'all_documents') {
                return {
                  ...act,
                  [`${type}_enabled`]: checked
                }
              } else {
                return act.id === action.id ? {
                  ...act,
                  [`${type}_enabled`]: checked
                } : (
                  act.name === 'all_documents' ? {
                    ...act,
                    [`${type}_enabled`]: false
                  } : act
                )
              }
            } else {
              return act.id === action.id ? {
                ...act,
                [`${type}_enabled`]: checked
              } : act
            }
          })
        }
      })
    })
  }

  const postActions = () => {
    try{
      const payload = {
        actions: module.flatMap((mod) => {
          return mod.actions.map((act) => {
            return {
              id: act.action_id ? act.id : null,
              action_id: act.action_id ? act.action_id : act.id,
              push_enabled: act.push_enabled ?? false,
              email_enabled: act.email_enabled ?? false
            }
          })
        })
      }
      postPlatformModuleActions(payload)
      Swal.fire({
        icon: 'success',
        title: 'Notification settings updated successfully.',
        showConfirmButton: false,
        timer: 1500,
      })
      props.toggle()
    }
    catch(error){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.response?.data?.message || 'Failed to update notification settings'
      })
    }
  }

  useEffect(() => {
    fetchModules()
  },[])

  return(
    <React.Fragment>
      <Offcanvas isOpen={props.isOpen} toggle={() => props.toggle() } direction="end" style={{width: '667px'}} className="offcanvas-end border-0 bottom_save_offcanvas">
        <div style={{ borderBottom: '1px solid #CAD2D7' }} className="d-flex justify-content-between align-items-center p-3">
          <h4 className="bold_heading mb-0">Notification Settings</h4>
          <Button className='d-inline-flex transparent_btn only_icon' onClick={() => props.toggle(false)}>
            <i className="ri-close-circle-line"></i>
          </Button>
        </div>
        <OffcanvasBody className="p-0">
          <SimpleBar className="simplebar_holder">
            <div className='p-4'>
              <div className="table-responsive table-card cstm_padding_table">
                <table className="table align-middle table-nowrap" id="customerTable">
                  <thead className="table-dark">
                    <tr>
                      <th>Module</th>
                      <th>Event/Action</th>
                      <th>Push</th>
                      <th>Email</th>
                      <th>Note</th>
                    </tr>
                  </thead>
                  <tbody className="list form-check-all">
                    {module && module.length > 0 && module?.map((module, keyIndex) => (
                      module.actions?.map((action, actionIndex) => (
                        <tr key={actionIndex}>
                          {actionIndex === 0 && <td rowSpan={module.actions.length}>
                            {module.display_name}
                          </td>}
                          <td>{action.display_name}</td>
                          <td>
                            <input
                              type="checkbox"
                              checked={action?.push_enabled || (action.name === 'all_updates' && module.actions.filter(action => action.push_enabled).length === 3)}
                              onChange={(e) => handleCheckboxChange(action, 'push', e.target.checked)}
                              style={{ cursor: 'pointer' }}
                            />
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              checked={action?.email_enabled || (action.name === 'all_updates' && module.actions.filter(action => action.email_enabled).length === 3)}
                              onChange={(e) => handleCheckboxChange(action, 'email', e.target.checked)}
                              style={{ cursor: 'pointer' }}
                            />
                          </td>
                          <td>
                            <Tooltip title={action.note} arrow
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    bgcolor: '#ecf1f5',
                                    color: '#041b29',
                                    '& .MuiTooltip-arrow': {
                                      color: '#ecf1f5',
                                    },
                                  },
                                },
                              }}
                            >
                              <IconButton  className='p-0'>
                                <i className="ri-information-line"></i>
                              </IconButton>
                            </Tooltip>
                          </td>
                        </tr>
                      ))
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </SimpleBar>
          <div className='save_btm_holder text-center'>
            <Button color="primary" className='d-inline-flex gradient_blue_bg' onClick={() => postActions()}>
              <span>Save</span>
            </Button>
            <Button color="danger" className='d-inline-flex gradient_red_bg' style={{ marginLeft: '10px' }} onClick={() => props.toggle()}>
              <span>Cancel</span>
            </Button>
          </div>
        </OffcanvasBody>
      </Offcanvas>
    </React.Fragment>
  )
}
export default Settings