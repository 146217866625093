/* eslint-disable no-unused-vars */
import React, {
  useEffect,
  useState
} from "react"
import {
  Navigate,
  Route,
  useNavigate
} from "react-router-dom"
// import { setAuthorization } from "../helpers/api_helper"
import { useDispatch } from "react-redux"

import { useProfile } from "../Components/Hooks/UserHooks"
import { userDetailsApi } from "../pages/Authentication/Login/api"
import { storeUserDetails } from "../store/auth"
import Cookies from "js-cookie"
import { CircularProgress } from "@mui/material"
// import { CircularProgress } from "@mui/material"

// import { logoutUser } from "../store/actions"

const AuthProtected = (props) => {
  // const currentPathName = window.location.pathname

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const {
    user,
    token
  } = useProfile()
  const navigate = useNavigate()

  useEffect(()=> {
    setTimeout(()=> {
      getUser()
    }, 1000)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(()=> {
    if(user){
      setLoading(false)
    }
  }, [user])

  const getUser = async () => {
    try {
      const getUser = await userDetailsApi()
      dispatch(storeUserDetails({ userDetails: getUser }))
    } catch (error) {
      Cookies.remove("token")
      navigate('/login')
    }
  }
  // if(!user && !token){
  //   // return <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
  //   return <h1>No user no token</h1>
  // }else if(token && !user){
  //   return <h1>No user, yes token</h1>
  // }else if(user){
  //   // return <h1>User</h1>
  //   return <>{props.children}</>
  // }
  // return <h1>checking dashboad</h1>

  if(!user && !token){
    return <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
  }else if(token && !user){
    return loading ?
      <div className="loader-alignment w-full">
        <div style={{ textAlign: 'center' }}>
          <CircularProgress color="info" size={100} />
          <div className="mt-4" style={{fontSize: '20px', fontWeight: 'bold', color: 'inherit'}}>Getting everything ready for you...</div>
        </div>
      </div> : null
  }
  return <>{props.children}</>
}

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (<> <Component {...props} /> </>)
      }}
    />
  )
}

export { AuthProtected, AccessRoute }
