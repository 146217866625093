import React from 'react'
import { useProfile } from '../Components/Hooks/UserHooks'
import { Navigate } from 'react-router-dom'

const Public = (props) => {
  const { token } = useProfile()

  if(token){
    return (
      <Navigate to={{ pathname: "/dashboard", state: { from: props.location } }} />
    )
  }
  return <>{props.children}</>  
 
}

export default Public