import React, { useCallback, useEffect, useState } from 'react'
import { Card, CardBody, Container } from 'reactstrap'
import Settings from './settings'
import { getNotificationData } from './api'
import PaginationComponent from '../../Components/Common/Pagination'
import { convertDateTimeString } from '../../helpers/date-time'

const Notifications = () => {
  document.title ="Notificatons | Vardian"
  const [sortBy, setSortBy] = useState({})
  const [meta, setMeta] = useState({})

  const columns = [
    { name: 'Date', key: 'created_at' },
    { name: 'Notification Details', key: 'notification' },
  ]

  const handleSort = (column) => {
    if (sortBy.column === column) {
      setSortBy({
        column,
        order: sortBy.order === 'desc' ? 'asc' : 'desc',
      })
    } else {
      setSortBy({ column, order: 'desc' })
    }
  }

  const [detail_list, setdetail_list] = useState(false)
  const tog_detail_list = () => {
    setdetail_list(!detail_list)
  }

  const [notifications,setNotifications] = useState([])
  const fetchData = useCallback(async() => {
    const response = await getNotificationData(sortBy)
    setNotifications(response.data.data.data)
    setMeta(response.data.data.meta)
  },[sortBy])

  useEffect(() => {
    fetchData()
  },[fetchData])

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <div className='main_page_heading_holder d-flex justify-content-between align-items-center pb-3'>
            <h4 className="bold_heading mb-0">All Notifications</h4>
            {/* <div>
              <Button color="dark" outline className='d-inline-flex' onClick={tog_detail_list}>
                < i class="ri-settings-2-line"></i>
                <span className='ps-1'>Settings</span>
              </Button>
            </div> */}
          </div>
          <Card>
            <CardBody>
              <div id="customerList">
                <div className="table-responsive table-card">
                  {notifications.length > 0 ? (
                    <table className="table align-middle table-nowrap resizeable_table" id="customerTable">
                      <thead className="table-dark">
                        <tr>
                          {columns?.map((column,index) => (
                            <td
                              key={column.key}
                              onClick={() => handleSort(column.key)}
                              className={`sortable-header ${sortBy.column !== column.key ? 'not-sorted' : ''} heading_holder bg-dark`}
                            >
                              {column.name}
                              {sortBy.column === column.key && (
                                <span className="d-inline-flex align-items-center" style={{ fontSize: '12px', marginLeft: '6px', color: 'blue' }}>
                                  {sortBy.order === 'asc' ? <i className="ri-arrow-up-s-fill"></i> : <i className="ri-arrow-down-s-fill"></i>}
                                </span>
                              )}
                              {sortBy.column !== column.key && (
                                <span className="d-inline-flex align-items-center" style={{ fontSize: '12px', marginLeft: '6px', color: '#ccc' }}>
                                  <i className="ri-arrow-up-s-fill"></i>
                                  <i className="ri-arrow-down-s-fill"></i>
                                </span>
                              )}
                            </td>
                          ))}
                        </tr>
                      </thead>

                      <tbody className="list form-check-all">
                        {notifications.map((notification,index) => (
                          <tr key={index}>
                            <td>{convertDateTimeString(notification.created_at)}</td>
                            <td>{notification.content?.title} : {notification.content?.body} </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p style={{ textAlign: 'center', fontSize: '16px' ,paddingTop: '30px'}}>No data found</p>
                  )}
                </div>
              </div>
            </CardBody>
            <div className="d-flex justify-content-between m-2">
              {notifications.length > 0 && (
                <PaginationComponent
                  listCall={getNotificationData}
                  params={sortBy}
                  paginationMeta={meta}
                  emitItem={(newValue) => setNotifications(newValue)}
                />
              )}
            </div>
          </Card>
        </Container>
        <Settings isOpen={detail_list} toggle={tog_detail_list} />
      </div>
    </React.Fragment>
  )
}

export default Notifications