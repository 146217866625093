export const dateFormatFlatPicker="M d, Y"
export const dateTimeFormatFlatPicker="M d, Y H:i"
export const dateTimeFormat="MMM DD, YYYY HH:mm"
export const dateFormat="MMM DD, YYYY"
export const timeFormat="HH:mm"

export const limitOptions = [5, 10, 20, 50, 100]

export const planName = {
  TRIAL: "Trial",
  ENTERPRISE: "Enterprise",
  // PROFESSIONAL: "Professional"
}

export const termName = {
  MONTH: "Monthly",
  YEAR: "Yearly"
}

export const EnterpriseCompanyType = {
  ON_PREM: 'On-Prem',
  ON_CLOUD_VARDIAN: 'On-Cloud Vardian',
  ON_CLOUD_COMPANY: 'On-Cloud Company'
}