import React from "react"
import { Navigate } from "react-router-dom"
//Dashboard
import Dashboard from "../pages/Dashboard/index"

import BasicPasswReset from '../pages/AuthenticationInner/PasswordReset/BasicPasswReset'

import Maintenance from '../pages/Pages/Maintenance/Maintenance'
import ComingSoon from '../pages/Pages/ComingSoon/ComingSoon'
//import SiteMap from '../pages/Pages/SiteMap/SiteMap'

import CoverPasswReset from '../pages/AuthenticationInner/PasswordReset/CoverPasswReset'
import BasicLockScreen from '../pages/AuthenticationInner/LockScreen/BasicLockScr'
import CoverLockScreen from '../pages/AuthenticationInner/LockScreen/CoverLockScr'
import BasicLogout from '../pages/AuthenticationInner/Logout/BasicLogout'
import CoverLogout from '../pages/AuthenticationInner/Logout/CoverLogout'
import BasicSuccessMsg from '../pages/AuthenticationInner/SuccessMessage/BasicSuccessMsg'
import CoverSuccessMsg from '../pages/AuthenticationInner/SuccessMessage/CoverSuccessMsg'
import BasicTwosVerify from '../pages/AuthenticationInner/TwoStepVerification/BasicTwosVerify'
import CoverTwosVerify from '../pages/AuthenticationInner/TwoStepVerification/CoverTwosVerify'
import Basic404 from '../pages/AuthenticationInner/Errors/Basic404'
import Cover404 from '../pages/AuthenticationInner/Errors/Cover404'
import Alt404 from '../pages/AuthenticationInner/Errors/Alt404'
import Error500 from '../pages/AuthenticationInner/Errors/Error500'

import BasicPasswCreate from "../pages/AuthenticationInner/PasswordCreate/BasicPasswCreate"
import CoverPasswCreate from "../pages/AuthenticationInner/PasswordCreate/CoverPasswCreate"
import Offlinepage from "../pages/AuthenticationInner/Errors/Offlinepage"

//login
import Login from "../pages/Authentication/Login"
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword/index"
import ResetPassword from "../pages/Authentication/Reset-password"

//Notifications
import Notifications from "../pages/Notifications"

//Subscriptions
import Subscriptions from "../pages/Subscriptions"

const authProtectedRoutes = [
  { path: "/reset-password", component: <ResetPassword /> },
  { path: "/dashboard", component: <Dashboard/> },
  { path: "/notifications", component: <Notifications /> },
  { path: "/subscriptions", component: <Subscriptions /> },

  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component:  <Navigate to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/auth-pass-reset-basic", component: <BasicPasswReset /> },
  { path: "/auth-pass-reset-cover", component: <CoverPasswReset /> },
  { path: "/auth-lockscreen-basic", component: <BasicLockScreen /> },
  { path: "/auth-lockscreen-cover", component: <CoverLockScreen /> },
  { path: "/auth-logout-basic", component: <BasicLogout /> },
  { path: "/auth-logout-cover", component: <CoverLogout /> },
  { path: "/auth-success-msg-basic", component: <BasicSuccessMsg /> },
  { path: "/auth-success-msg-cover", component: <CoverSuccessMsg /> },
  { path: "/auth-twostep-basic", component: <BasicTwosVerify /> },
  { path: "/auth-twostep-cover", component: <CoverTwosVerify /> },
  { path: "/auth-404-basic", component: <Basic404 /> },
  { path: "/auth-404-cover", component: <Cover404 /> },
  { path: "/auth-404-alt", component: <Alt404 /> },
  { path: "/auth-500", component: <Error500 /> },
  { path: "/pages-maintenance", component: <Maintenance /> },
  { path: "/pages-coming-soon", component: <ComingSoon /> },

  { path: "/auth-pass-change-basic", component: <BasicPasswCreate /> },
  { path: "/auth-pass-change-cover", component: <CoverPasswCreate /> },
  { path: "/auth-offline", component: <Offlinepage /> },

]

export { authProtectedRoutes, publicRoutes }