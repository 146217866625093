import { Pagination, Stack, FormControl, Select, MenuItem } from "@mui/material"
import React, { useEffect, useState } from "react"
import Swal from "sweetalert2"
import { limitOptions } from "../../helpers/constants"

const PaginationComponent = ({ tableName, listCall, params, paginationMeta, emitItem, emitSelectedLimit = () => { } }) => {

  const [mounted, setMounted] = useState(false)
  const [limit, setLimit] = useState(paginationMeta?.per_page ?? 10)
  const [currentPage, setCurrentPage] = useState(1)
  const [meta, setMeta] = useState(paginationMeta)

  const handlePagination = (event, value) => {
    setCurrentPage(value)
  }

  useEffect(() => {
    setMeta(paginationMeta)
  }, [paginationMeta])

  const fetchData = async () => {
    try {
      let queryParams = params
      queryParams.page = currentPage
      queryParams.limit = limit
      const res = await listCall(queryParams)
      setMeta(res.data.data.meta)
      emitItem(res.data.data.data)
      emitSelectedLimit(limit)
      return res
    } catch (err) {
      console.log(err)
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Encountered fetching problem'
      })
    }
  }

  const handleLimitChange = (event) => {
    setLimit(event.target.value)
    setCurrentPage(1)
  }

  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    if (mounted) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, currentPage])


  return (
    <>
      <div className="d-flex justify-content-start align-items-center">
        <div className="me-2 pagination-text">Rows per page: </div>
        <FormControl>
          <Select
            value={limit}
            onChange={handleLimitChange}
            displayEmpty
            sx={{ width: '100%', height: '40px' }}
          >
            {limitOptions.map(option => (
              <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <div className="me-4 pagination-text">
  Showing entries {isNaN((currentPage - 1) * limit + 1) ? 0 : (currentPage - 1) * limit + 1} to {isNaN(Math.min(((currentPage - 1) * limit) + limit)) ? 0 : Math.min(((currentPage - 1) * limit) + limit)} of {isNaN(meta?.total) ? '0' : meta?.total}
        </div>
        <Stack spacing={2}>
          <Pagination count={meta?.last_page} shape="rounded" variant="outlined" showFirstButton showLastButton onChange={handlePagination}/>
        </Stack>
      </div>
    </>
  )
}

export default PaginationComponent