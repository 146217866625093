import React, { useState } from 'react'
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap'
import FeedbackForm from '../pages/Help/Feedback/index'

const Footer = () => {
  const [openFeedbackModal, setopenFeedbackModal] = useState(false)

  // const handleFeedbackModal = () => {
  //   setopenFeedbackModal(true)
  // }

  const handleCloseFeedbackModal = () => {
    setopenFeedbackModal(false)
  }

  return (
    <React.Fragment>
      {/* <footer className="footer">
        <UncontrolledDropdown className="dropdown d-inline-block">
          <DropdownToggle className="btn btn-sm p-1" tag="button">
            <i className="ri-question-line"></i>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem href="/tickets"><i className="ri-coupon-3-line align-bottom me-2"></i>Ticket Module</DropdownItem>
            <DropdownItem href="/help-topics"><i className="ri-questionnaire-line align-bottom me-2"></i>Help Topics</DropdownItem>
            <DropdownItem onClick={handleFeedbackModal}> <i className="ri-feedback-line align-bottom me-2"></i>Give Us Feedback</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </footer> */}



      <Modal isOpen={openFeedbackModal} centered scrollable className='delete_confirmation_modal full_white_modal no_header_title_modal'>
        <ModalHeader className="flexbox_holder bg-light py-1 p-3">
          <Button className='d-inline-flex transparent_btn only_icon' onClick={handleCloseFeedbackModal}>
            <i className="ri-close-circle-line"></i>
          </Button>
        </ModalHeader>
        <ModalBody>
          <FeedbackForm />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default Footer