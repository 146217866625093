import { ROUTES } from '../../../api/Routes'
import axios from '../../../utils/axios/auth'

const resetPasswordApi = async({ new_password, old_password }) => {
  try {
    const response = await axios.post(ROUTES.POST.RESET_PASSWORD, { new_password, old_password })
    return response.data
    
  } catch (error) {
    return Promise.reject(error)
  }
}

export {
  resetPasswordApi
}