import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  user: {},
}
const slice = createSlice({
  name: 'Profile',
  initialState: initialState,
  reducers: {},
})
export default slice.reducer