import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types"
import withRouter from '../Components/Common/withRouter'

//import Components
import Header from './Header'
import Sidebar from './Sidebar'
import Footer from './Footer'
// import RightSidebar from '../Components/Common/RightSidebar'

//import actions
// import {
//   changeLayout,
//   changeSidebarTheme,
//   changeLayoutMode,
//   changeLayoutWidth,
//   changeLayoutPosition,
//   changeTopbarTheme,
//   changeLeftsidebarSizeType,
//   changeLeftsidebarViewType,
//   changeSidebarImageType
// } from "../store/actions"

//redux
import { useSelector } from "react-redux"
import { layoutModeTypes, layoutPositionTypes, layoutTypes, layoutWidthTypes, leftSidebarImageTypes, leftSidebarTypes, leftSidebarViewTypes, leftsidbarSizeTypes, preloaderTypes, topbarThemeTypes } from '../Components/constants/layout'

const Layout = (props) => {
  const [headerClass, setHeaderClass] = useState("")
  // const dispatch = useDispatch()
  const {
    layoutType,
    // leftSidebarType,
    layoutModeType,
    // layoutWidthType,
    // layoutPositionType,
    // topbarThemeType,
    // leftsidbarSizeType,
    // leftSidebarViewType,
    // leftSidebarImageType
  } = useSelector(state => ({
    layoutType: layoutTypes.VERTICAL,
    leftSidebarType: leftSidebarTypes.DARK,
    layoutModeType: layoutModeTypes.LIGHTMODE,
    layoutWidthType: layoutWidthTypes.FLUID,
    layoutPositionType: layoutPositionTypes.FIXED,
    topbarThemeType: topbarThemeTypes.LIGHT,
    leftsidbarSizeType: leftsidbarSizeTypes.DEFAULT,
    leftSidebarViewType: leftSidebarViewTypes.DEFAULT,
    leftSidebarImageType: leftSidebarImageTypes.NONE,
    preloader: preloaderTypes.DISABLE
  }))

  /*
    layout settings
  //   */
  // useEffect(() => {
  //   if (
  //     layoutType ||
  //           leftSidebarType ||
  //           layoutModeType ||
  //           layoutWidthType ||
  //           layoutPositionType ||
  //           topbarThemeType ||
  //           leftsidbarSizeType ||
  //           leftSidebarViewType ||
  //           leftSidebarImageType
  //   ) {
  //     dispatch(changeLeftsidebarViewType(leftSidebarViewType))
  //     dispatch(changeLeftsidebarSizeType(leftsidbarSizeType))
  //     dispatch(changeSidebarTheme(leftSidebarType))
  //     dispatch(changeLayoutMode(layoutModeType))
  //     dispatch(changeLayoutWidth(layoutWidthType))
  //     dispatch(changeLayoutPosition(layoutPositionType))
  //     dispatch(changeTopbarTheme(topbarThemeType))
  //     dispatch(changeLayout(layoutType))
  //     dispatch(changeSidebarImageType(leftSidebarImageType))
  // }
  // }, [layoutType,
  //   leftSidebarType,
  //   layoutModeType,
  //   layoutWidthType,
  //   layoutPositionType,
  //   topbarThemeType,
  //   leftsidbarSizeType,
  //   leftSidebarViewType,
  //   leftSidebarImageType,
  //   dispatch])
  /*
    call dark/light mode
  //   */
  // const onChangeLayoutMode = (value) => {
  //   if (changeLayoutMode) {
  //     dispatch(changeLayoutMode(value))
  //   }
  // }

  // class add remove in header 
  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true)
  })

  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop
    if (scrollup > 50) {
      setHeaderClass("topbar-shadow")
    } else {
      setHeaderClass("")
    }
  }

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header
          headerClass={headerClass}
          layoutModeType={layoutModeType} />
        <Sidebar
          layoutType={layoutType}
        />
        <div className="main-content">
          {props.children}
          <Footer />
        </div>
      </div>
      {/* <RightSidebar /> */}
    </React.Fragment>

  )
}

Layout.propTypes = {
  children: PropTypes.any,
}

export default withRouter(Layout)
