/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { dateFormat } from '../../helpers/constants'
import { convertDateTimeString } from '../../helpers/date-time'

const Activity = ({ activity , meta , attachment , loadMore}) => {
  const [activityArray, setActivityArray] = useState([])

  const getInitials = (name) => {
    return name?.split(' ').map((word) => word.charAt(0)).join('')
  }

  const loadingRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [nextPage, setNextPage] = useState(2)

  useEffect(()=>{
    setActivityArray((prevActivity) => {return [...prevActivity, ...activity]})
  },[activity])

  useEffect(() => {
    const handleIntersection = async (entries, observer) => {
      entries.forEach(async (entry) => {
        if (entry.isIntersecting && meta.next) {
          setLoading(true)

          try {
            await loadMore(nextPage)
            if (Array.isArray(activity)) {
              setNextPage((prevPage) => prevPage + 1)
            } else {
              console.error('loadMore did not return an iterable:', activity)
            }
          } catch (error) {
            console.error('Error fetching more data:', error)
          } finally {
            setLoading(false)
          }
        }
      })
    }

    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    }

    const observer = new IntersectionObserver(handleIntersection, observerOptions)

    if (loadingRef.current && meta.next) {
      observer.observe(loadingRef.current)
    }

    return () => {
      if (loadingRef.current) {
        observer.disconnect()
      }
    }
  }, [loadingRef.current, nextPage])

  const toTitle = (slug) => slug?.toLowerCase().split(/[-_.\s]/).map((w) => `${w.charAt(0)?.toUpperCase()}${w.substr(1)}`).join(' ')

  function updateKeyName(key) {
    const obj = {
      is_active: 'Status'
    }

    return obj[key] ?? toTitle(key)
  }

  function changeKeyValue(key, value) {
    let changedValue = value
    if (key === 'is_active') {
      changedValue = value ? 'Active' : 'Inactive'
    }

    if (key.includes('date')) {
      changedValue = convertDateTimeString(value, dateFormat)
    }

    return changedValue
  }

  const displayActivity = (activity) => {
    const allUpdateActivities = []
    const messages = []

    function modifyDiffValue (diffValue) {
      if (Array.isArray(diffValue)) {
        return diffValue.map((dValue) => modifyDiffValue(dValue))
      }

      if (typeof diffValue === 'object' && diffValue !== null) {
        const allKeys = Object.keys(diffValue)
        allKeys.forEach((key) => {
          if (Array.isArray(diffValue[key])) {
            diffValue[key] = diffValue[key]?.map((dValue) => modifyDiffValue(dValue))
          } else if (typeof diffValue[key] === 'object') {
            if (Object.hasOwn(diffValue[key], 'old') && Object.hasOwn(diffValue[key], 'new')) {
              allUpdateActivities.push({key,...diffValue[key]})
              return diffValue
            } else {
              diffValue[key] = modifyDiffValue(diffValue[key])
            }
          }
        })
        return diffValue
      }
      return diffValue
    }

    if(activity.event === 'create'){
      messages.push(`New Subscription has been added for ${activity?.payload?.company_name}`)
    } else if (activity.event === 'update'){
      modifyDiffValue(activity?.payload)
      allUpdateActivities.forEach((act) => {
        let msg = ''
        const key = updateKeyName(act?.key)
        if ((act.old !== null || act.old !== '') && (act.new !== null || act.new !== '')) {
          msg = `${key} has been updated from ${changeKeyValue(act?.key, act?.old)} to ${changeKeyValue(act?.key, act?.new)}`
        } else if ((act.old === null || act.old === '') && (act.new !== null || act.new !== '')) {
          msg = `${key} has been set to ${changeKeyValue(act?.key, act?.new)}`
        } else if ((act.old !== null || act.old !== '') && (act.new === null || act.new === '')) {
          msg = `${key} has been set to empty.`
        }
        messages.push(msg)
      })
    }

    return (
      <>
        {messages.map((msg, index) => (
          <React.Fragment key={index}>
            <div><i>{msg}</i></div>
          </React.Fragment>
        ))}
      </>
    )
  }

  const displayActivityHeader = ({ event, createdBy }) => {
    const obj = {
      'create': 'added',
      'update': 'updated'
    }

    return `Subscription ${obj[event]} by ${createdBy}`
  }

  return (
    <div className='timeline_holder p-3'>
      {activityArray?.length > 0 ? (
        <>
          <h4 className='heading-4'>{('Latest Activities')}</h4>
          <ul className='list_holder'>
            {activityArray.map((activityItem, index) => (
              <li key={index}>
                <div className='name_initials_holder'>{getInitials(activityItem?.user ? activityItem?.user?.name : activityItem?.super_admin?.name)}</div>
                <div className='full_content_holder'>
                  <div className='name_holder'>
                    {displayActivityHeader({
                      event: activityItem.event,
                      createdBy: activityItem?.user ? activityItem?.user?.name : activityItem?.super_admin?.name
                    })}
                  </div>
                  <div className='time_holder'>
                    <i>{moment(activityItem.created_at).fromNow()}</i>
                  </div>
                  <div className='content'>{displayActivity(activityItem)}</div>
                  <div>
                    {activityItem?.event === 'create' && attachment ? (
                      <div>
                        <div className='content'>
                          <p>{('Attachment')}</p>
                          <a className='anchor_holder' href={attachment.signed_url}>
                            <span className='filename pe-2'>{attachment.file_url.split('.com/')[1]}</span>
                          </a>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </li>
            ))}
            <div ref={loadingRef}></div>
          </ul>
          {loading ? <CircularProgress color="inherit" size={20} /> : null}
        </>
      ) : "No recent activity"}
    </div>
  )
}

export default Activity

