import React, { useCallback, useEffect, useRef} from 'react'
import { Button, Col, Offcanvas, OffcanvasBody, Row,} from 'reactstrap'
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import SimpleBar from "simplebar-react"
import Flatpickr from 'react-flatpickr'
import { dateFormatFlatPicker} from '../../../helpers/constants'


const Filter = (props) => {

  const {
    openFilterSidebar,
    toggleFilterSidebar,
    value,
    emitFilter,
    plans
  } = props

  const inputDateRef = useRef()

  const toggleSidebar = () => {
    toggleFilterSidebar()
  }
  // const [disabled,setDisabled]=useState(true)

  const [filter, setFilter] = React.useState({
    company_name: '',
    subscription_plan: '',
    next_renewal_date: '',
    term: ''
  })

  const handleChange = useCallback((field, value) => {
    if(field === 'next_renewal_date'){
      const year = value.getFullYear()
      const month = String(value.getMonth() + 1).padStart(2, '0')
      const day = String(value.getDate()).padStart(2, '0')

      setFilter({...filter, [field]: `${year}-${month}-${day}`})
    }else{
      setFilter({...filter, [field]: value})
    }
  }, [filter])

  function removeEmptyValues(obj) {
    const result = {}

    for (const key in obj) {
      if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
        result[key] = obj[key]
      }
    }

    return result
  }

  const handleApplyFilter = () => {
    toggleSidebar()
    const filteredValues = removeEmptyValues(filter)
    emitFilter({
      keys: Object.keys(filteredValues),
      values: Object.values(filteredValues)
    })
  }

  const handleResetFilter = () => {
    toggleSidebar()
    setFilter({
      company_name: '',
      subscription_plan: '',
      next_renewal_date: '',
      term: ''
    })
    emitFilter({
      keys: [],
      values: []
    })
  }

  useEffect(() => {
    for (let i; i <= value?.keys?.length; i++) {
      setFilter(...filter, ...{[value.keys[i]]: value.values[i]})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
  }, [filter])

  return (
    <React.Fragment>
      {/* Filter sidebar */}
      <Offcanvas isOpen={openFilterSidebar} toggle={toggleSidebar} direction="end" className="offcanvas-end border-0 bottom_save_offcanvas">
        <div className="d-flex justify-content-between align-items-center p-4 pb-0">
          <h4 className="bold_heading mb-0">Filter</h4>
          <Button className='d-inline-flex transparent_btn only_icon' onClick={toggleSidebar}>
            <i className="ri-close-circle-line"></i>
          </Button>
        </div>
        <OffcanvasBody className="p-0">
          <SimpleBar className="simplebar_holder">
            <div className="p-4">
              <form>
                <Row>
                  <Col xl={12} className='mb-3'>
                    <TextField
                      fullWidth
                      label="Company Name"
                      variant="outlined"
                      value={filter.company_name}
                      onChange={(e) => {
                        handleChange('company_name', e.target.value)
                      }}
                    />
                  </Col>
                  <Col xl={6} className='mb-3'>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                      Subscription Plan
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Subscription Plan"
                        value={filter.subscription_plan}
                        onChange={(e) => {
                          handleChange('subscription_plan', e.target.value)
                        }
                        }
                        MenuProps={{PaperProps: {style: {maxHeight: '200px',},},}}
                      >
                        {plans?.map((plan) => (
                          <MenuItem key={plan.id} value={plan.name}>
                            {plan.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xl={6} className="mb-3">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                      Term
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Term"
                        value={filter.term}
                        onChange={(e) => {
                          handleChange('term', e.target.value)
                        }}
                        MenuProps={{PaperProps: {style: {maxHeight: '200px',},},}}
                      >
                        <MenuItem value={"Monthly"}>Monthly</MenuItem>
                        <MenuItem value={"Yearly"}>Yearly</MenuItem>
                      </Select>
                    </FormControl>
                  </Col>
                  <Col lg={12} md={12} className='mb-3'>
                    <div className='flatpickr_with_icon'>
                      <Flatpickr
                        placeholder=""
                        className="form-control"
                        id="datepicker-publish-input"
                        options={{
                          altInput: true,
                          clickOpens: false,
                          altFormat: dateFormatFlatPicker,
                          dateFormat: "Y-m-d",
                          minDate: "today",
                        }}
                        ref={inputDateRef}
                        value={filter.next_renewal_date|| ""}
                        onChange={(e) => handleChange('next_renewal_date', e[0])}
                      />
                      <button className='icon_btn' type='button'
                        onClick={() => {
                          if (inputDateRef.current && inputDateRef.current.flatpickr) {
                            inputDateRef.current.flatpickr.open()
                          }
                        }}
                      >
                        <i className='ri-calendar-line'></i>
                      </button>
                      {!filter.next_renewal_date && <span style={{color: 'gray'}} className='placeholder_span'>Next Renewal Date</span>}
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </SimpleBar>
          <div className='save_btm_holder text-center'>
            <Button color="primary" className='d-inline-flex gradient_blue_bg' onClick={handleApplyFilter} disabled={(!filter.company_name && !filter.next_renewal_date && !filter.subscription_plan && !filter.term)}>
              <span>Apply Filter</span>
            </Button>
            <Button color="danger" className='d-inline-flex m-1 danger_red_btn' onClick={handleResetFilter} style={{ marginLeft: '10px' }} disabled={(!filter.company_name && !filter.subscription_plan && !filter.next_renewal_date && !filter.term)}>
              <span>Reset Filter</span>
            </Button>
          </div>
        </OffcanvasBody>
      </Offcanvas>
    </React.Fragment>
  )
}

export default Filter