export const ROUTES = {
  POST: {
    LOGIN: '/auth/super-admin-login',
    COMPANIES: '/companies',
    TABLE_MANAGEMENT: '/table-management',
    STRIPE_REFUND: '/stripe/create-refund-session'
  },
  GET: {
    USER_DETAILS: '/super-admin/me',
    COMPANIES: '/companies',
    SUBSCRIPTION_PLANS: '/subscription-plans',
    TABLE_MANAGEMENT: '/table-management/table',
    DASHBOARD_SUBSCRIPTION_BY_PLAN: 'dashboard/subscription/plan',
    DASHBOARD_SUBSCRIPTION_BY_TERM: 'dashboard/subscription/term',
    DASHBOARD_SUBSCRIPTION_YEARLY: 'dashboard/subscription/yearly',
    DASHBOARD_USER_YEARLY: 'dashboard/users/yearly',
    DASHBOARD_COMPANY_COUNT: 'dashboard/company/present-count',
    DASHBOARD_USER_COUNT: 'dashboard/user/present-count',
    ACTIVITY: 'super-admin-activity/',
    LICENSE_KEY: '/companies/generate-license-key'
  },
  DELETE: {
    COMPANIES: '/companies'
  },
  PUT: {
    COMPANIES: '/companies'
  },
  PATCH:{
  }
}
