import { configureStore } from '@reduxjs/toolkit'
import authReducer from './auth'
import profileReducer from './profile'
import userReducer from './user'

const store = configureStore({
  reducer: {auth : authReducer,
    Profile: profileReducer,
    user: userReducer},
})
export default store