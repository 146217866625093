import Cookies from "js-cookie"
import { useSelector } from "react-redux"

const useProfile = () => {
  let { user } = useSelector(state => {
    return {
      user: state.auth.login.user
    }}
  )
  const token = Cookies.get('token')
  return { user, token }
}

export { useProfile }