import { ROUTES } from "../../api/Routes"
import axios from "../../utils/axios/auth"
// import qs from 'qs'

const getPlatformModuleActions = async() => {
  try {
    const response = await axios.get(ROUTES.GET.PLATFORM_MODULES_ACTIONS)
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}

const postPlatformModuleActions = async(payload) => {
  try {
    const response = await axios.post(ROUTES.POST.NOTIFICATION_ACTION,payload)
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}

const getNotificationSettings = async() => {
  try {
    const response = await axios.get(ROUTES.GET.NOTIFICATION_SETTINGS)
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}

const getNotificationData = async(param) => {
  try {
    // const params = {
    //   order: param.column,
    //   direction: param.order,
    //   page: param.page ?? 1,
    //   filterFields: param.filterFields,
    //   filterInputs: param.filterInputs,
    //   search: param.search,
    //   limit: param.limit
    // }
    // const response = await axios.get(ROUTES.GET.NOTIFICATION_LIST, {params, paramsSerializer: params => {
    //   return qs.stringify(params, {indices: false})
    // }})
    const response = {data:
    {
      data:
    {
      data:[{"id": 3617,
        "module": {
          "name": "work_order_management",
          "display_name": "Work Order Management"
        },
        "user": {
          "name": "CR Tester"
        },
        "is_read": false,
        "content": {
          "body": "Work Order with title test-12 and id WO370548 has been updated",
          "event": "workOrder.status_update",
          "route": "workOrder",
          "title": "Work Order no #WO370548's status has been updated",
          "metadata": {
            "work_order_id": 454
          }
        },
        "created_at": "2024-04-22T13:31:48.305Z"
      },
      {"id": 3617,
        "module": {
          "name": "work_order_management",
          "display_name": "Work Order Management"
        },
        "user": {
          "name": "CR Tester"
        },
        "is_read": false,
        "content": {
          "body": "Form with title form-12 and id WO370548 has been submitted",
          "event": "workOrder.status_update",
          "route": "workOrder",
          "title": "Form no #WO370548's status has been updated",
          "metadata": {
            "work_order_id": 454
          }
        },
        "created_at": "2023-04-22T13:31:48.305Z"
      }]
    }
    }
    }
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}

export { getNotificationSettings , getPlatformModuleActions , getNotificationData , postPlatformModuleActions}